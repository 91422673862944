<template>
  <div class="main">
    <div class="pulldown">
      <div class="select">
        <el-select v-model="selectvalue" placeholder="请选择设备">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button class="btn" type="primary">确定</el-button>
      </div>
    </div>
    <div class="table">
      <div class="title">{{ p }}数据展示</div>
      <div class="header">
        <div>频率:HZ</div>
        <div>温度:1℃</div>
        <div>湿度:5°</div>
        <div>触点测温:1℃</div>
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="parameter" label="参数"> </el-table-column>
          <el-table-column prop="A" label="A相">
            <!-- <template slot-scope="scope">
              <p>{{ eightToten(scope.row.unit) }}</p>
            </template> -->
          </el-table-column>
          <el-table-column prop="B" label="B相"> </el-table-column>
          <el-table-column prop="C" label="C相"> </el-table-column>
          <el-table-column prop="phase" label="合相"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
var str = "00012300325300";
str = str.replace(/\b(0+)/gi, "");
console.log(str);
export default {
  data() {
    return {
      p: "",
      tableData: [
        {
          parameter: "相电压",
          A: "v1",
          B: "v2",
          C: "v3",
          phase: "v4",
        },
        {
          parameter: "电流",
          A: "I1",
          B: "I2",
          C: "I3",
          phase: "v4",
        },
        {
          parameter: "线电压",
          A: "I1",
          B: "I2",
          C: "I3",
          phase: "v4",
        },
      ],
      options: [
        // MAC  00 11 22 33 44 55 22 11 01 00 02 33 55 00 08 07
        { value: "00", label: "00" },
        { value: "11", label: "11" },
        { value: "22", label: "22" },
        { value: "33", label: "33" },
        { value: "44", label: "44" },
        { value: "55", label: "55" },
      ],
      // 选择设备
      selectvalue: "",

      device: [{ title: "设备1", value: false, tip: "" }],
      setInter: "",
    };
  },
  mounted() {
    this.getMessage(0);
    // var that = this
    // this.setInter = setInterval(function () {
    //   that.getMessage(1)
    // }, 1000 * 10)
  },
  methods: {
    // 八进制转10进制
    eightToten(e) {
      let a = e.replace(/\s*/g, "");
      return (parseInt(a, 16) / 100).toFixed(2);
    },
    changeSwitch(e, i) {
      console.log(e, i);
    },
    getMessage(i) {
      var that = this;
      console.log(i);
      var ws = new WebSocket("ws://115.220.6.10:10900");
      ws.onopen = function () {
        var json = { name: "testweb" };
        if (i == 0) {
          ws.send(JSON.stringify(json));
        } else {
          ws.send(" ");
        }
      };
      ws.onmessage = function (e) {
        // console.log(e.data)
        console.log(JSON.parse(e.data));
        let a = JSON.parse(e.data);
        if (a.data == "net is connected") {
          that.tableData = [];
        } else {
          that.tableData = a.data;
          that.p = a.mac;
          console.log(a.mac);
          // 向设备端发送我已成功接收消息
          let b = { mac: a.mac, type: "02", command: "01" };
          ws.send(JSON.stringify(b));
        }
      };
      ws.onerror = function () {
        console.log("发生异常了");
      };
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  margin: 20px;

  .table {
    background-color: aliceblue;
    margin: 10px 0;
    padding: 10px 0;

    .title {
      width: 100%;
      text-align: center;
      margin: 0 auto 10px;
      font-size: 20px;
    }
  }

  .header {
    background-color: #fff;
    height: 40px;
    line-height: 40px;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    padding: 0 20px;
  }

  .pulldown {
    background-color: #fff;

    display: flex;
    margin-bottom: 20px;

    .select {
      margin: 20px;
      // border-right: 1px solid #000;
    }

    .btn {
      margin: 0 20px;
    }

    .switchControl {
      margin: 20px;
      text-align: center;

      .instruct {
        display: flex;
        justify-content: space-around;
      }

      .deviceSwitch {
        margin: 10px;
        display: flex;
      }
    }
  }
}
</style>
